<template>
    
  <v-main style="background-color:#F2F3F8">

 
    <div>
          <v-alert v-show="false"
           style="margin-top:13px"  
                color="red darken-1"
                dark
                icon="mdi-hammer-screwdriver"
                border="left"
                elevation="2"
                prominent>
                This page is scheduled for work. Currently, it is a prototype page.
          </v-alert>
 
        </div>

       <v-container>

         <v-chip-group class="ma-5"
         mandatory
        column
        v-model="selectedCategory"
        color="red">

        <v-chip
         filter
         large
         outlined
          v-for="cate in category"
          :key="cate"
          :value="cate"
        >
          {{ cate }}
        </v-chip>
         
      </v-chip-group>
        <v-row  >

          <v-col  class="mt-5"  
            v-for="item in selectedCategoryList"
            :key="item.name"
            cols="12"
            sm="3">

            <v-card elevation="0">

              <v-list style="background-color:#F2F3F8">
                 <v-list-item>

                  <v-avatar  width="65"
                     height="65"
                     rounded="lg"
                     color="black">

                 <v-img
                   :src="item.img"  @click="openLink(item.url)"></v-img>
                 </v-avatar>
                
                   <v-list-item-content class="ml-3">
                     <v-list-item-title>{{item.name}}</v-list-item-title>
                     <v-list-item-subtitle >{{item.cat}}</v-list-item-subtitle>
                   </v-list-item-content>

                 </v-list-item>

              </v-list>

            </v-card>
          </v-col>

     <v-col cols="12" >
        <div style="height:200px"></div>
      </v-col>

        </v-row>
      </v-container>
 
  </v-main>

</template>

<script>
  export default {
    data () {
      return { 

        category:[ 'All', 'Games', 'DeFi','Wallets','Utils','Exchanges', 'Marketplaces', 'BlockExplorer','SisterChains','Other'],
        selectedCategory:'All',
        selectedCategoryList:[],
        dappsList :[
          {
            name:'Upland',
            cat:'Games',
            img:'https://dashboard-assets.dappradar.com/document/3388/upland-games-eos-logo-50x50_e6b347dd682126c7dceb525b4e414d9a.png',
            url:'https://www.upland.me/'

          },
           {
            name:'Prospectors',
            cat:'Games',
            img:'https://dashboard-assets.dappradar.com/document/3029/prospectors-dapp-games-wax-logo-50x50_57095164395940be4bb479c9039d7029.png',
            url:'https://prospectors.io'

          },
           {
            name:'AtomicHub',
            cat:'Marketplaces',
            img:'https://dashboard-assets.dappradar.com/document/3915/atomicassets-dapp-collectibles-wax-logo-50x50_ab10916baefa840b29ce77aa405fae7d.png',
            url:'https://eos.atomichub.io/'

          },
           {
            name:'Crypto Dynasty',
            cat:'Games',
            img:'https://dashboard-assets.dappradar.com/document/2151/cryptodynastyeos-dapp-games-eos-logo-50x50_aaf48643211031a4a0f5f79dbebe5b05.png',
            url:'https://eos.cryptodynasty.one/'

          },
          {
            name:'Wombat Dungeon Master',
            cat:'Games',
            img:'https://dashboard-assets.dappradar.com/document/12162/wombatdungeonmaster-dapp-games-eos-logo-50x50_3bb936230b35691056f20db1976b45c1.png',
            url:'https://dungeon.wombat.app/'

          },
           {
            name:'Defibox',
            cat:'DeFi',
            img:'https://dashboard-assets.dappradar.com/document/4025/defibox-dapp-defi-eos-logo-50x50_437e40fd137dc270377c6b9abd185203.png',
            url:'https://dapp.defibox.io/'

          },
          {
            name:'Blockchain Cuties',
            cat:'Games',
            img:'https://dashboard-assets.dappradar.com/document/233/blockchaincuties-dapp-games-ethereum-logo-166x166_b44660a17447026beb31a0fb917562df.png',
            url:'https://blockchaincuties.com/'
          },
          {
            name:'PIZZA',
            cat:'DeFi',
            img:'https://dashboard-assets.dappradar.com/document/2180/pizzausde-dapp-defi-eos-logo-166x166_4b9991fd9da1ab35ce0a6a9a3fe23606.png',
            url:'https://pizza.finance/'
          },
          {
            name:'Newdex',
            cat:'Exchanges',
            img:'https://dashboard-assets.dappradar.com/document/1767/newdex-dapp-exchanges-eos-logo-166x166_15651356369dae67105119babaf8c970.png',
            url:'https://newdex.io/'
          },
          {
            name:'DeFis Network',
            cat:'DeFi',
            img:'https://dashboard-assets.dappradar.com/document/3972/defisnetwork-dapp-defi-eos-logo-166x166_06aadc22296e9c9931cf6ba941c195e7.png',
            url:'https://www.defis.network/'
          },
          {
            name:'Boid',
            cat:'Other',
            img:'https://dashboard-assets.dappradar.com/document/2285/boid-dapp-other-eos-logo-166x166_32cbdb301a6abedfa00dbf296355a69d.png',
            url:'https://www.boid.com/'
          },
          {
            name:'VIGOR',
            cat:'DeFi',
            img:'https://dashboard-assets.dappradar.com/document/2259/vigor-dapp-other-eos-logo-166x166_2e399dfc57c7122b2a4c414c9c20c1eb.png',
            url:'https://vigor.ai/'
          },
          {
            name:'DAPP Network',
            cat:'Other',
            img:'https://dashboard-assets.dappradar.com/document/7609/dappnetwork-dapp-other-eos-logo-166x166_27a1481a0720c400d8fae528515d76d7.png',
            url:'https://www.liquidapps.io/'
          },
          {
            name:'EOSMARKETPLACE',
            cat:'DeFi',
            img:'https://dashboard-assets.dappradar.com/document/5049/eosmarketplace-dapp-defi-eos-logo-166x166_bbb1214257368cae5a747e41f2495e90.png',
            url:'https://eosmarketplace.io/'
          },
          {
            name:'pEOS',
            cat:'Other',
            img:'https://dashboard-assets.dappradar.com/document/2230/peos-dapp-other-eos-logo-166x166_3e5e9a14f02000ed6e1c4a3c6071348d.png',
            url:'https://peos.one/'
          },
          {
            name:'Everipedia',
            cat:'Other',
            img:'https://dashboard-assets.dappradar.com/document/1776/everipedia-dapp-other-eos-logo-166x166_6bc5d58c9a01d303982786acf2f2e688.png',
            url:'https://everipedia.org/'
          },
          {
            name:'EOS Microloan',
            cat:'DeFi',
            img:'https://dashboard-assets.dappradar.com/document/3815/eosmicroloan-dapp-defi-eos-logo-166x166_85b9fea1021ba77c661dde20885309d1.png',
            url:'https://eosmicroloan.com/'
          },
          {
            name:'ChallengeDapp',
            cat:'Other',
            img:'https://dashboard-assets.dappradar.com/document/2265/challengedapp-dapp-other-eos-logo-166x166_b6c6f248dd4bc631963aae2d20234334.png',
            url:'https://www.challengedapp.io/'
          },
          {
            name:'Crypto Sword & Magic',
            cat:'Games',
            img:'https://dashboard-assets.dappradar.com/document/2172/cryptoswordmagic-dapp-games-eos-logo-166x166_21e78c2130d7f41fe94ae68b73642600.png',
            url:'http://cryptoswordandmagic.com'
          },
          {
            name:'Consortium',
            cat:'Other',
            img:'https://dashboard-assets.dappradar.com/document/3406/consortium-other-eos-logo-166x166_4aa2a2d75f9748176ee411da4fb2ea69.png',
            url:'https://app.consortium.vote/'
          },
          {
            name:'ChainZ Arena - Play and Earn',
            cat:'Games',
            img:'https://dashboard-assets.dappradar.com/document/3544/chainzarenaplayandearn-dapp-games-eos-logo-166x166_0e12e4503b3443edcfc3c8f79f5eaad0.png',
            url:'https://www.chainzarena.com/'
          },
            {
            name:'HireVibes',
            cat:'Other',
            img:'https://dashboard-assets.dappradar.com/document/2069/hirevibes-dapp-other-eos-logo-166x166_50a991f8211fc160f91696747a77a10a.png',
            url:'https://www.hirevibes.com/'
          },
          {
            name:'Equilibrium - EOSDT',
            cat:'DeFi',
            img:'https://dashboard-assets.dappradar.com/document/2170/equilibriumeosdt-dapp-defi-eos-logo-166x166_081397a1217355bb40d726dc8b83855d.png',
            url:'https://equilibrium.io/'
          },
            {
            name:'T-Starter',
            cat:'DeFi',
            img:'https://dashboard-assets.dappradar.com/document/7590/tstarter-dapp-defi-eos-logo-166x166_97e82f142154da587ace508f83084e0a.png',
            url:'https://tstarter.io/'
          },
            {
            name:'Dmd.Finance',
            cat:'DeFi',
            img:'https://dashboard-assets.dappradar.com/document/4463/dmdfinance-dapp-defi-eos-logo-166x166_c7054a50b59df1b994cb5f3dd175ae6a.png',
            url:'https://dmd.finance/'
          },
            {
            name:'DolphinSwap',
            cat:'DeFi',
            img:'https://dashboard-assets.dappradar.com/document/4789/dolphinswap-dapp-defi-eos-logo-166x166_9bca13a3c6841e3d12e0cb19b387a423.png',
            url:'https://dolphinswap.io/'
          },
          {
            name:'EOS Template One',
            cat:'Other',
            img:'https://dashboard-assets.dappradar.com/document/15188/eostemplateone-dapp-other-eos-logo-166x166_042428fc0fb0ad68c2df940ce507f6ea.png',
            url:'https://eostemplateone.com/'
          },
           {
            name:'Alcor.exchange',
            cat:'Exchanges',
            img:'https://dashboard-assets.dappradar.com/document/3572/alcorexchange-dapp-exchanges-wax-logo-166x166_ae4f4f142db2f504e76eaf7de03ce0d2.png',
            url:'https://wax.alcor.exchange/'
          },
          {
            name:'SimpleAssets',
            cat:'Other',
            img:'https://dashboard-assets.dappradar.com/document/10316/simpleassets-dapp-other-telos-logo-166x166_79a51dd98c4cae9c0580fc8d6cc01bb5.png',
            url:'https://simpleassets.io/'
          },
            {
            name:'Emanate',
            cat:'Other',
            img:'https://dashboard-assets.dappradar.com/document/3970/emanate-dapp-defi-eos-logo-166x166_2ddae3cf68e9b71481ddf7e08315e8be.png',
            url:'https://emanate.live/'
          },
          {
            name:'GenPool.io',
            cat:'Other',
            img:'https://dashboard-assets.dappradar.com/document/3843/genpoolio-dapp-marketplaces-eos-logo-166x166_3dca45c957b768daa7a6cd68d4394847.png',
            url:'https://genpool.io/'
          },
            {
            name:'tipit',
            cat:'Other',
            img:'https://dashboard-assets.dappradar.com/document/1987/tipit-dapp-other-eos-logo-166x166_ce786b71eea0d5ac2956a436a43d99b3.png',
            url:'https://tipit.io/'
          },
          {
            name:'Womplay',
            cat:'Games',
            img:'https://dashboard-assets.dappradar.com/document/12377/womplay-dapp-games-eos-logo-166x166_80484492bc3e318b441bf7b1e4b2d9c3.png',
            url:'https://womplay.io/'
          },
          {
            name:'Wombat',
            cat:'Wallets',
            img:'https://dashboard-assets.dappradar.com/document/12377/womplay-dapp-games-eos-logo-166x166_80484492bc3e318b441bf7b1e4b2d9c3.png',
            url:'https://www.wombat.app/'
          },
          {
            name:'Anhcor',
            cat:'Wallets',
            img:'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iMTYwIiBoZWlnaHQ9IjE2MCIgdmlld0JveD0iMCAwIDI1NiAyNTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGcgc3R5bGU9IiIgdHJhbnNmb3JtPSJtYXRyaXgoMC45LCAwLCAwLCAwLjksIDEyLjc5OTk5NSwgMTIuNzk5OTk1KSI+CiAgICA8Y2lyY2xlIGN4PSIxMjgiIGN5PSIxMjgiIHI9IjEyOCIgZmlsbD0iIzM2NTBBMiIgc3R5bGU9IiIvPgogICAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0gMTI4LjAxIDQ4IEMgMTMxLjY4OSA0OCAxMzUuMDQ0IDUwLjEwMiAxMzYuNjQ3IDUzLjQxMiBMIDE3NS4wNTcgMTMyLjYxMyBMIDE3NS45MjQgMTM0LjQgTCAxNTQuNTg3IDEzNC40IEwgMTQ4LjM3OCAxMjEuNiBMIDEwNy42NCAxMjEuNiBMIDEwMS40MzMgMTM0LjQgTCA4MC4wOTQgMTM0LjQgTCA4MC45NjMgMTMyLjYxMSBMIDExOS4zNzIgNTMuNDEyIEMgMTIwLjk3OCA1MC4xMDIgMTI0LjMzMSA0OCAxMjguMDEgNDggWiBNIDExNS40IDEwNS42MDEgTCAxNDAuNjE5IDEwNS42MDEgTCAxMjguMDEgNzkuNjAxIEwgMTE1LjQgMTA1LjYwMSBaIE0gMTU2Ljc5OCAxNjEuNiBMIDE3Ni4wMDggMTYxLjYgQyAxNzUuNDMgMTg3LjQ0MyAxNTQuMDM5IDIwOCAxMjguMDEgMjA4IEMgMTAxLjk4MyAyMDggODAuNTg5IDE4Ny40NDMgODAuMDEyIDE2MS42IEwgOTkuMjIgMTYxLjYgQyA5OS42NzEgMTczLjM2NyAxMDcuNDg5IDE4My40MDkgMTE4LjM5OSAxODcuMTk1IEwgMTE4LjM5OSAxNDguODAxIEMgMTE4LjM5OSAxNDMuNDk5IDEyMi42OTggMTM5LjIgMTI4IDEzOS4yIEMgMTMzLjMwMiAxMzkuMiAxMzcuNjAxIDE0My40OTkgMTM3LjYwMSAxNDguODAxIEwgMTM3LjYwMSAxODcuMjAxIEMgMTQ4LjUyMiAxODMuNDIxIDE1Ni4zNDkgMTczLjM3NiAxNTYuNzk4IDE2MS42IFoiIGZpbGw9IndoaXRlIiBzdHlsZT0iIi8+CiAgPC9nPgo8L3N2Zz4=',
            url:'https://greymass.com/en/anchor/'
          },
          {
            name:'TokenPoket',
            cat:'Wallets',
            img:'https://bloks.io/cdn-cgi/image/width=28,height=28/https://dapp.tokenpocket.pro/token-logo/EOS_tokendapppub_TPT.png',
            url:'https://www.tokenpocket.pro/'
          },
          {
            name:'SimpleEOS',
            cat:'Wallets',
            img:'https://avatars.githubusercontent.com/u/38332581?s=200&v=4',
            url:'https://eosrio.io/simpleos/'
          },
          {
            name:'Scatter',
            cat:'Wallets',
            img:'https://avatars.githubusercontent.com/u/40841390?s=200&v=4',
            url:'https://github.com/GetScatter/ScatterDesktop/releases/'
          },
          {
            name:'EOSPowerUp.io',
            cat:'Utils',
            img:'https://pbs.twimg.com/profile_images/1399201127279869954/Fw-6csLR_400x400.jpg',
            url:'https://eospowerup.io/free'
          },
           {
            name:'EOS Account Creator',
            cat:'Utils',
            img:'https://ui-avatars.com/api/?background=214455&color=fff&length=1&name=EOS%20Account%20Creator&size=170',
            url:'https://eos-account-creator.com/'
          },
          {
            name:'Bloks.io',
            cat:'BlockExplorer',
            img:'https://bloks.io/img/bloks_logomark_white.svg',
            url:'https://bloks.io/'
          },
           {
            name:'EOS Flare',
            cat:'BlockExplorer',
            img:'https://res.cloudinary.com/dwkobzcjz/image/fetch/w_50,h_50,c_fill,r_5/https://eosflare.io/static/img/icons/eosflare-256.png',
            url:'https://eosflare.io/'
          },
           {
            name:'EOS Authority',
            cat:'BlockExplorer',
            img:'https://res.cloudinary.com/dwkobzcjz/image/fetch/w_50,h_50,c_fill,r_5/https://eosauthority.com/common/bp-standard-info/EOS-AUTHORITY-256.jpg',
            url:'https://eosauthority.com/'
          },
           {
            name:'EOSX',
            cat:'BlockExplorer',
            img:'https://prod-eoskit-cdn-aws.shangxianle.com/static/assets/eosx-logo_e7a12dadd12eadd5a56946d0d2d7f065.png',
            url:'https://www.eosx.io/'
          },

        ]

      }
    },

  watch:{

    selectedCategory : function(val){

      if(val == 'All')
      {
         this.selectedCategoryList = this.dappsList
      }
      else{

        this.selectedCategoryList = []

        this.dappsList.map((value,index) => {

          if(value.cat == val)
          {
            this.selectedCategoryList.push(value)
          }

        })
        
      }

    },

  },

   methods: {

    openLink(linkURL)
     {
        if(linkURL=="") return;
        window.open(linkURL, '_blank');
     },
   },
   mounted()
   {
     this.selectedCategoryList = this.dappsList

    //wallets anchor,https://www.wombat.app/,tokenpoket,scatter,SimplEOS
    //utilities들 powerup,create eos account
    //

   }
   
  }

  


</script>